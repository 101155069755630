import React, { useContext, useState } from "react";
import { Input as AntInput, Button as AntButton, Tooltip, Input, notification } from "antd";
import { AlignLeftOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../../context/Language";
import { AppContext } from "../../../../../context/App";
import Modal from "../../../../Modal";
import Select from "../../../../Form/Select";
import Button from "../../../../Buttons/GeneralButton";
import UpcCalls from "../../../../../classes/upc/upc";
import IsrcCalls from "../../../../../classes/isrc/isrc";
import AdminCalls from "../../../../../classes/admin/admin";
const { TextArea } = AntInput;

const AddUpcIsrcModal = ({
  onCancel,
  onConfirm,
  isModalVisible,
  upcErr,
  isrcErr,
  assignUpc = false,
  typeCode = "upc",
  releaseId,
  loadRelease,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { codesOptions } = useContext(AppContext);

  const [currentCode, setCurrentCode] = useState("");
  const [selectedTypeCode, setSelectedTypeCode] = useState(typeCode);
  const [countCodes, setCountCodes] = useState(0);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const upcRegex = /^[0-9]$/;
  const isrcRegex = /^[a-zA-Z]{2}[a-zA-Z0-9]{3}[0-9]{2}[0-9a-zA-Z]{5}$/;
  const onlyNumbersAndSpaces = /^[0-9 ]*$/;

  const submitHandler = () => {
    setErrors([]);
    const codes = currentCode
      .trim()
      .replace(/[\s-]|\r|\n/g, "")
      .split(",")
      .filter((el) => el.trim() !== "");

    if (codes.length === 0) {
      setErrors([dictionary.emptyField]);
      return;
    }

    const newErrors = [];
    let i = 0;
    if (selectedTypeCode === "upc") {
      while (i < codes.length && newErrors.length === 0) {
        if (assignUpc) {
          if (codes[i].length < 12) newErrors.push(dictionary.upcErr3);

          break;
        } else {
        
          if (codes[i].length < 12) newErrors.push(dictionary.upcErr2);

          if (!upcRegex.test(codes[i])) newErrors.push(dictionary.upcErr);

          i++;
        }
      }
    } else {
      while (i < codes.length && newErrors.length === 0) {
        if (codes[i].length < 12) newErrors.push(dictionary.isrcErr2);

        if (!isrcRegex.test(codes[i])) newErrors.push(dictionary.isrcErr);

        i++;
      }
    }

    setErrors(newErrors);
    if (newErrors.length === 0) {
      setIsLoading(true);
      if (selectedTypeCode === "upc") {
        UpcCalls.addUpc({ upc: codes.join(",") })
          .then(() => assignAndConfirm())
          .catch(() => setErrors([dictionary.errorGeneral]))
          .finally(() => setIsLoading(false));

      } else {
        IsrcCalls.addIsrc({ isrc: codes.join(",") })
          .then(() => onConfirm())
          .catch(() => setErrors([dictionary.errorGeneral]))
          .finally(() => setIsLoading(false));
      }
    }
  };

  const assignAndConfirm = () => {
    AdminCalls.releaseUPC(releaseId).then((response) =>{
      if (!response.error) {
        loadRelease();
        setIsLoading(true);
        setTimeout(() => {
          notification.success({
            key: "releaseDetailNotification",
            placement: "bottomRight",
            message: dictionary.successGeneral,
            duration: 5,
          },400);
        })
      } else{
          notification.error({
            key: "releaseDetailNotification",
            placement: "bottomRight",
            message: dictionary.upcError,
            duration: 4,
          });
      }
    }).catch(() => {
      notification.error({
        key: "releaseDetailNotification",
        placement: "bottomRight",
        message: dictionary.errorGeneral,
        duration: 4,
      });
    }).finally(() => {
      setIsLoading(false)
      onConfirm();
    });
  }

  const changeHandler = (e) => {
    setErrors([]);
    if(assignUpc) {
      if(!onlyNumbersAndSpaces.test(e.target.value)) {
        setErrors(dictionary.onlyNumbersAndSpaces);
      } else{
        setCurrentCode(e.target.value);
      }
    } else {
      const value = e.target.value;
      setCurrentCode(value);
      setCountCodes(value.match(/,/g) ? value.match(/,/g).length + 1 : 0);
    }
  };

  const formatCode = () => {
    setCurrentCode(
      currentCode
        .trim()
        .replace(/[\s-]|\r|\n/g, "")
        .replace(/,/g, ",\r")
    );
  };

  return (
    <Modal
      idModal="AddUpcIsrcModal"
      destroyOnClose
      maskClosable={false}
      className="add-upc"
      handleCancel={onCancel}
      isModalVisible={isModalVisible}
      title={
        <div className="modal-title">
          {dictionary.addNewCode}
        </div>
      }
      body={
        <div className="forms">
          {assignUpc ? (
            <div className="text-area-label">{dictionary.assignNewUpc}</div>
          ) : (
          <Select
            name="label"
            required={assignUpc ? false :true}
            disableOpt={true}
            options={codesOptions}
            label={dictionary.type}
            value={selectedTypeCode}
            disabled={assignUpc}
            placeholder={dictionary.select}
            className={" selector-general"}
            onChange={(e) => setSelectedTypeCode(e)}
          />
          )}
          <div className="text-area-label">
            {selectedTypeCode !== "upc"
              ? dictionary.isrcDesc
              : assignUpc 
                ? dictionary.upcDesc + "."
                : dictionary.upcDesc + dictionary.upcDesc2 + "."}
          </div>
          {assignUpc ? (
            <Input 
              disabled={isLoading}
              className={
                (upcErr || isrcErr ? "inputErr" : "") + " input-general"
              }
              name="description"
              value={currentCode}
              onChange={changeHandler}
            />
          ) : (
            <React.Fragment>
              <Tooltip title={dictionary.format}>
                <AntButton
                  id="format-code"
                  type="text"
                  shape="circle"
                  onClick={formatCode}
                  icon={<AlignLeftOutlined />}
                />
              </Tooltip>
              <TextArea
                disabled={isLoading}
                className={
                  (upcErr || isrcErr ? "inputErr" : "") + " text-area-general"
                }
                name="description"
                value={currentCode}
                placeholder={
                  dictionary.eg +
                  (selectedTypeCode !== "upc"
                    ? " US-ABC-12-34567, US ABC 12 34568, USABC1234569..."
                    : " 123456789012, ...")
                }
                onChange={changeHandler}
              />
              <div className="text-area-label">{countCodes} </div>
            </React.Fragment>
          )}
          {errors.length ? (
            <div className="text-error">
              {assignUpc ?
                <p>{errors}</p>
              : errors.map((a, i) => (
                <p key={"AddUpcIsrcModalErr-" + i}>{a}</p>
              ))}
            </div>
          ) : null}
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={onCancel}
          />
          <Button
            size="md"
            key="submit"
            className="footer-btn"
            onClick={submitHandler}
            disabled={isLoading}
            text={
              selectedTypeCode === "upc"
              ? assignUpc
                ? dictionary.addUpcAssign
                :dictionary.addUpc
              : dictionary.addIsrc
            }
          />
        </div>
      }
    />
  );
};

export default AddUpcIsrcModal;

import { Button, Typography, Popconfirm, Spin, notification } from "antd";
import React, { useContext, useState } from "react";
import { LanguageContext } from "../../../../../context/Language";
import AdminCalls from "../../../../../classes/admin/admin";

const UPCSetter = ({
  releaseId,
  disabled,
  upc,
  loadRelease,
  setShowAddUpcIsrcModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dictionary } = useContext(LanguageContext);
  
  const assignUPC = () => {
    notification.destroy(); // Clear all notifications
    setIsLoading(true);
    AdminCalls.releaseUPC(releaseId)
      .then((response) => {
        if (!response.error) {
          loadRelease();
          setIsLoading(true);
          setTimeout(() => {
            notification.success({
              key: "releaseDetailNotification",
              placement: "bottomRight",
              message: dictionary.successGeneral,
              duration: 5,
            },400);
          });
        } else {
          if (response.message === "notAvailableUpc"){
            setShowAddUpcIsrcModal(true);
          }
          else{
            notification.error({
              key: "releaseDetailNotification",
              placement: "bottomRight",
              message: dictionary.errorGeneral,
              duration: 4,
            });
          }
        }
      })
      .catch(() => {
        notification.error({
          key: "releaseDetailNotification",
          placement: "bottomRight",
          message: dictionary.errorGeneral,
          duration: 4,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;
        <Spin className="sping-assign" />
      </Typography.Title>
    );

  if (upc)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;{upc}
      </Typography.Title>
    );

  if (disabled)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;
        <Button disabled={disabled} type={"primary"} className="assign-upc">
          {dictionary.assign}
        </Button>
      </Typography.Title>
    );

  return (
    <Typography.Title className="mb-0" level={4}>
      UPC:&nbsp;
      <Popconfirm
        icon={null}
        placement="right"
        onConfirm={assignUPC}
        okText={dictionary.yes}
        cancelText={dictionary.no}
        title={dictionary.upcRequestConfirm}
      >
        <Button type={"primary"} className="assign-upc" disabled={disabled}>
          {dictionary.assign}
        </Button>
      </Popconfirm>
    </Typography.Title>
  );
};

export default UPCSetter;

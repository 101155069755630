import React, { useEffect, useContext, useState } from "react";
import { Table, DatePicker, Popconfirm, Spin } from "antd";
import { UserContext } from "../../../context/User";
import { AppContext } from "../../../context/App";
import { LanguageContext } from "../../../context/Language";
import { StopOutlined, EditOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "../../Form/Select";
import Search from "../../Form/Search";
import SearchIcon from "../../Icon/SearchIcon";
import Button from "../../Buttons/GeneralButton";
import CrossIcon from "../../Icon/CrossIcon";
import CheckIcon from "../../Icon/CheckIcon";

const TableUsers = ({
  list,
  error,
  columns,
  subjects,
  setNewList,
  title,
  loadList,
  showModalUpdateStatus,
  search,
  getSearch,
  initialized, 
  setIinitialized
}) => {

  const { RangePicker } = DatePicker;
  const { dictionary } = useContext(LanguageContext);
  const { permission } = useContext(UserContext);
  const { userTypeOptions, userPlanOptions, affirmationOptions } = useContext(AppContext);
  const [dataSource, setTableData] = useState([]);
  const [originalTable, setOriginalTable] = useState([...subjects]);
  // const [isModalVisible, showModalRelease] = useState(false);
  const [orderTable, setOrder] = useState();
  const [filter, getFilter] = useState(0);
  const [filters, filterOptions] = useState([]);
  const [filterName, getFilterName] = useState(0);
  const [filterVerified, getFilterVerified] = useState(0);
  const [filterType, getFilterType] = useState(0);
  const [filterPlan, getFilterPlan] = useState(0);
  const [filterCreated, getFilterCreated] = useState([]);
  const [filterUpdated, getFilterUpdated] = useState([]);
  const [orderAlph] = useState([
    { _id: "A-Z", name: "A-Z" },
    { _id: "Z-A", name: "Z-A" },
  ]);

  useEffect(() => {
      getList();
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    getList();
    getFilters();
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    if (!initialized && subjects.length) {
      setOriginalTable(subjects);
      setIinitialized(true);
    }
    // eslint-disable-next-line
  }, [subjects.length, list]);

  const getFilters = () => {
    const list = [];
    for (let i = 1; i < columns.length; i++) {
      let nameCol = columns[i].dataIndex;
      if (nameCol !== "detail" && nameCol !== "details" &&
          nameCol !== "email" &&
          nameCol !== "labels" &&
          nameCol !== "status" &&
          nameCol !== "artists"
        ) {
          list.push({ _id: nameCol, name: columns[i].title });
        }
    }
    filterOptions(list);
  };

  const saveOrderAZ = (names, newOrder = null) => {
    const order = newOrder !== null ? newOrder : orderTable;
    const sortered = [...names].sort(function (a, b) {
      const x = (a.profile?.name) || '';
      const y = (b.profile?.name) || '';
      if (order === "A-Z") {
        if (x && y)
          return x.localeCompare(y);
      } else if (order === "Z-A") {
        if (x && y)
          return y.localeCompare(x);
      } else {
        return b.createdAt.localeCompare(a.createdAt);
      }
    });
    return sortered;
  };

  const saveSection = (
    data,
    filterVar = 0,
    attr,
    isDate = false,
    isBoolean = false,
    isUpdate = false
  ) => {
    let dataNames = [...data];
    if (isDate) {
      if (filterVar.length !== 0) {
        dataNames = data.filter((el) => {
          const itemDate = isUpdate
            ? moment.utc(el.updatedAt).startOf("day")
            : moment.utc(el.createdAt).startOf("day");
          return (
            itemDate >= moment.utc(filterVar[0]).startOf("day") &&
            itemDate <= moment.utc(filterVar[1]).startOf("day")
          );
        });
      }
    } else if (isBoolean) {
      if (filterVar !== 0) {
        dataNames = filterVar
          ? data.filter((el) => el[attr])
          : data.filter((el) => !el[attr]);
      }
    } else {
      if (filterVar !== 0) {
        if (attr === "verified") {
          dataNames = data.filter((el) => el[attr] === filterVar);
        } else if (attr === "plan"){
          dataNames = data.filter((el) =>
          el.contract && el.contract?.type && el.contract?.type.toLowerCase().includes(filterVar.toLowerCase())
        );
        } else if (attr === "type"){
          dataNames = data.filter((el) =>
          el.user_type && el.user_type.toLowerCase().includes(filterVar.toLowerCase())
        );
        } else {
          dataNames = data.filter((el) =>
          el[attr] && el[attr].toLowerCase().includes(filterVar.toLowerCase())
        );
        }
      }
    }
    return dataNames;
  };

  const alphabeticalSelect = async (e) => {
    setOrder(e);
    getFilterName(e);
    const saveOrder = await saveOrderAZ(list, e);
    setNewList(saveOrder);
  };

  const checkAllFilters = () => {
    const saveOrder = saveOrderAZ(originalTable);
    let saveType = saveSection(saveOrder, filterType, "type");
    let savePlan = saveSection(saveType, filterPlan, "plan");
    let saveVerified = saveSection(savePlan, filterVerified, "verified");
    let saveDates = saveSection(saveVerified, filterCreated, null, true);
    return saveDates;
  }

  const typeSelect = (e) => {
    
    getFilterType(e);
    const saveOrder = saveOrderAZ(originalTable);
    let savePlan = saveSection(saveOrder, filterPlan, "plan");
    let saveVerified = saveSection(savePlan, filterVerified, "verified");
    let saveDates = saveSection(saveVerified, filterCreated, null, true);
    let finalFilter = [...saveDates];
    if (e !== 0) {
      finalFilter = saveDates.filter((el) =>
      el.user_type && el.user_type.toLowerCase().includes(e.toLowerCase())
      );
    }
    setNewList(finalFilter);
    if(search) {
      searchInAdmin(search, finalFilter);
    }
  };

  const planSelect = (e) => {
    getFilterPlan(e);
    const saveOrder = saveOrderAZ(originalTable);
    let saveType = saveSection(saveOrder, filterType, "type");
    let saveVerified = saveSection(saveType, filterVerified, "verified");
    let saveDates = saveSection(saveVerified, filterCreated, null, true);
    let finalFilter = [...saveDates];
    if (e !== 0) {
      finalFilter = saveDates.filter((el) =>
        el.contract && el.contract?.type && el.contract?.type.toLowerCase().includes(e.toLowerCase())
      );
    }    
    setNewList(finalFilter);
    if(search) {
      searchInAdmin(search, finalFilter);
    }
  };

  const verifiedSelect = (e) => {
    getFilterVerified(e);
    const saveOrder = saveOrderAZ(originalTable);
    let saveType = saveSection(saveOrder, filterType, "type");
    let savePlan = saveSection(saveType, filterPlan, "plan");
    let saveCreated = saveSection(savePlan, filterCreated, null, true);
    let finalFilter = [...saveCreated];
    if (e !== 0) {
      finalFilter = saveCreated.filter((el) => el.verified === e);
    }
    setNewList(finalFilter);
    if(search) {
      searchInAdmin(search, finalFilter);
    }
  };

  const datesSelect = (date, isCreated = false, attr) => {
    const saveOrder = saveOrderAZ(originalTable);
    let saveType = saveSection(saveOrder, filterType, "type");
    let savePlan = saveSection(saveType, filterPlan, "plan");
    let saveVerified = saveSection(savePlan, filterVerified, "verified");
    let filteredDates = [...saveVerified];
    if (date) {
      let getDates = [
        moment.utc(date[0]).startOf("day"),
        moment.utc(date[1]).startOf("day"),
      ];
      if (isCreated) {
        getFilterCreated(getDates);
      } else {
        getFilterUpdated(getDates);
      }
      filteredDates = saveVerified.filter((el) => {
        const itemDate = moment.utc(el[attr]).startOf("day");
        return (
          itemDate >= moment.utc(date[0]).startOf("day") &&
          itemDate <= moment.utc(date[1]).startOf("day")
        );
      });
    } else {
      if (isCreated) {
        getFilterCreated([]);
      } else {
        getFilterUpdated([]);
      }
    }
    setNewList(filteredDates);
    if(search) {
      searchInAdmin(search, filteredDates);
    }
  };

  const filtersBar = () => {
    return filters.map((el) => {
      let setOptions = filters;
      let setFunction = (e) => handleSelect(e);

      let setValue = filter;
      switch (el._id) {
        case "name":
          setOptions = orderAlph;
          setFunction = (e) => alphabeticalSelect(e, el);
          setValue = filterName;
          break;
          case "type":
          setOptions = userTypeOptions;
          setFunction = (e) => typeSelect(e);
          setValue = filterType;
          break;
        case "plan":
          setOptions = userPlanOptions;
          setFunction = (e) => planSelect(e);
          setValue = filterPlan;
          break;
        case "verified":
          setOptions = affirmationOptions;
          setFunction = (e) => verifiedSelect(e, el);
          setValue = filterVerified;
          break;
        
      }
      if (el._id === "createdAt" || el._id === "updated") {
        let isCreate = el._id === "createdAt" ? true : false;
        let attr = el._id === "createdAt" ? el._id : "updatedAt";
        return (
          <div key={el._id} className="dateField">
            <div className="label">{dictionary[el._id]}</div>
            <RangePicker
              defaultValue={
                el._id === "createdAt" ? filterCreated : filterUpdated
              }
              onChange={(e) => datesSelect(e, isCreate, attr)}
              className="date-range-admn"
            />
          </div>
        );
      } else {
        return (
          <Select
            key={el._id}
            name="section"
            value={setValue}
            options={setOptions}
            placeholder={el.name}
            onChange={setFunction}
            className="selector-general search-sec"
          />
        );
      }
    });
  };

  const showNames = (names) => {
    let all = [];
    for (var i = 0; i < names.length; i++) {
      all.push(<li key={i}>{names[i]}</li>);
    }
    return <ul className="view-stores">{all}</ul>;
  };

  const getArtistList = (userArtists) => {
    let namesList = [];
    userArtists.forEach((el) => {
      namesList.push(el);
    });
    let order = orderedArtists(namesList);
    return order;
  };

  const orderedArtists = (list = []) => {
    let openList = showNames(list);
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (index <= 2) {
          if (index === 2 && list.length >= 3) {
            return (
              <Popconfirm
                key={index}
                title={openList}
                placement="bottom"
                okText={dictionary.close}
                icon={null}
                showCancel={false}
              >
                <div className="seemore-btn">{dictionary.seeMore}</div>
              </Popconfirm>
            );
          } else if (
            (index > 0 && list.length < 3) ||
            list.length === 1 ||
            (index === 2 && list.length === 3)
          ) {
            return el;
          } else if (index === 0 && list.length < 3 && list.length > 1) {
            return el + " " + dictionary.and + " ";
          } else {
            return el + ", ";
          }
        }
        return null;
      });
    }
  };

  const getList = async () => {
    let listArray = [];
    let count = 0;
    list.map((el, index) => {
      let allArtists = el.artists ? getArtistList(el.artists[0]) : "";
      let allLabels = el.labels ? getArtistList(el.labels[0]) : "";
      let estatus = dictionary[el.status];
      let plan = el.contract?.type == "royalty" ? "Plus" : "Core";
      let name = el.profile?.name ?
        (el.profile?.name+ " " + el.profile?.surname)
        : "--";
      let created = el.createdAt
        ? moment.utc(el.createdAt).startOf("day").format("YYYY/MM/DD")
        : null;
      count++;
      return listArray.push({
        index: index,
        key: el._id || index,
        id: count,
        completedPayee: (
          <div key={el._id} className="av-users">
            {el.payee_status && el.payee_status[0] ? (
              <CheckIcon
                color="#00CF85"
                className="payee-check"
              />
            ) : (
              <CrossIcon
                className="payee-cross"
              />
            )}
          </div>
        ),
        detail: (
          <div>
              <Link to={"/user/view?u=" + el._id + "&edit=true&section=user"}>
                <EditOutlined className="view-edit-rel"/>
              </Link>
              {el.status === "active" ? (
                <StopOutlined
                  className="disable-detail"
                  onClick={() => showModalUpdateStatus(el._id, name, "disable")}
                />
              ): (
                <CheckCircleOutlined
                  className="active-detail"
                  onClick={() => showModalUpdateStatus(el._id, name, "activate")}
                />
              )}
          </div>
        ),
        name: name,
        email: el.email || "",
        createdAt: created,
        plan: plan,
        labels: <div className="list-td-user">{allLabels}</div>,
        artists: <div className="list-td-user">{allArtists}</div>,
        verified: el.verified ? dictionary.yes : dictionary.no,
        type: <div className="user-type">{dictionary[el.user_type]}</div>,
        status: estatus,
      });
    });
    setTableData(listArray);
  };

  const searchInAdmin = (value, list = null) => {
    let getFilterData = checkAllFilters();
    let filteredNames = [...getFilterData];
    if (list) {
      getFilterData = [...list];
    }
    switch (filter) {
      case 0:
        (filteredNames = getFilterData.filter(
          (el) =>
            el.profile?.name?.toLowerCase().includes(value.toLowerCase()) ||
            el.profile?.surname?.toLowerCase().includes(value.toLowerCase()) ||
            el.email.toLowerCase().includes(value.toLowerCase()) ||
            moment(el.createdAt).format("YYYY/MM/DD").includes(value) ||
            el.artists[0]?.some(artist => typeof artist === 'string' && artist.toLowerCase().includes(value.toLowerCase())) ||
            el.labels[0]?.some(label => typeof label === 'string' && label.toLowerCase().includes(value.toLowerCase()))
        ));
        break;
      default:
        setNewList(getFilterData);
        break;
    }
    setNewList(filteredNames);
  };

  const onSearch = (search) => {
    getSearch(search.target.value);
    searchInAdmin(search.target.value);
  };

  const handleSelect = (e) => {
    getFilter(e);
  };

  return (
    <div className="tables">
      <div className="top-actions-srch">
        <div className="searchers">
          <Search
            name="searcher"
            value={search}
            mobile="responsive"
            onSearch={onSearch}
            className="searcher sec back"
            enterButton={<SearchIcon />}
            placeholder={dictionary.search}
          />
          {filtersBar()}
        </div>
        {(permission && permission.release && permission.release.add) && (title === "Releases" || title==="Lanzamientos") ?
          <Link to="/newRelease" className="new-release">
            <Button
              size="fullWidth"
              className="new-rel-btn"
              text={dictionary.createNewRel}
            />
          </Link>
        : null } 
      </div>
      <div className="general-card-admin">
        <div className="table-admin inline-contend">
          <div className="header-tab-ad"></div>
          <Spin spinning={loadList} className="spinner">
            <Table
              rowKey="index"
              columns={columns}
              pagination={true}
              dataSource={dataSource}
              className="content-table-admin users"
            />
          </Spin>
        </div>
        {error ? <p className="text-error relative-text">{error}</p> : null}
      </div>
    </div>
  );
};

export default TableUsers;